import React from "react";
import imageOne from "../../assets/image/media/home/png/ready.png";
import { Link } from "gatsby";

const CtaSec = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container bg-tra-purple pt-25 pt-lg-12 pb-13 pb-lg-18 border rounded-20 pr-12 pl-12">
        <div className="row align-items-center">
          {/* Hero Content */}
          <div className="col-xl-7 col-lg-7 col-md-6 col-xs-8 order-1 order-lg-1">
            <div className="mt-8 mt-lg-0">
              <h2 className="font-size-10 mb-8">Ready for Cloudstack?</h2>
              <p className="font-size-6 mb-0">
                Whatever stage you’re at - from an initial idea to a developed
                plan - let’s talk about how we can help you move forward.
              </p>
              <div className=" pt-5 pt-lg-10">
                <Link
                  to={"/contact"}
                  className="btn btn-fox-violet btn-sm-2 rounded-5"
                >
                  Talk to us
                </Link>
              </div>
            </div>
          </div>
          {/* End Hero Content */}
          {/* Hero Image */}
          <div className="col-xl-5  col-lg-5 col-md-6 col-xs-8 order-2 order-md-2">
            <div className="pt-lg-3">
              <div className="l3-content-image-5 mt-5 mt-lg-0">
                <img className="w-100" src={imageOne} alt="ctaImg" />
              </div>
            </div>
          </div>
          {/* End Hero Image */}
        </div>
      </div>
    </div>
  );
};

export default CtaSec;
