import React from "react";

const Title = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-8 col-md-10 col-xs-8 pt-20">
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 ">
                Got an idea? Have a question? Need help? Don't hesitate, drop us
                a line.
              </h2>
              <p className="font-size-7">
                Whatever you need, from an initial sense check on a concept, to
                support with an existing product, our friendly team is on hand
                to answer your query.
              </p>
            </div>
          </div>
        </div>
        {/* End Section title */}
      </div>
    </div>
  );
};

export default Title;
