import { Link } from "gatsby";
import React from "react";
import PageWrapper from "../components/PageWrapper";
import ContactForm from "../sections/contact/Form2";
import Title from "../sections/contact/Title";
import CtaSec from "../sections/homepage/cta";

const Contact = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <Link to="/contact">
                <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                  Let's talk
                </button>
              </Link>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Title />
        <ContactForm />
        <CtaSec />
      </PageWrapper>
    </>
  );
};
export default Contact;
