import React, { useState } from "react";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/88f7dac0-91e0-11ed-a003-6f0b76086b1c";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <div className="row justify-content-center mb-20">
          <div className="text-2xl">Thank you! We'll be in touch soon.</div>
        </div>
      </>
    );
  }

  return (
    <div className="pt-14 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10 ">
            <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST">
              <div className="col-md-10 col-xl-12 mb-6 input-subject">
                <h5>This question is about:</h5>
                <span className="mb-6">
                  Choose a topic, so we can put you in touch with the right team
                  member:
                </span>
                <select
                  name="topic"
                  className="form-control form-control-lg bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray  font-size-5"
                  id="topic"
                  required
                >
                  <option selected>This question is about...</option>
                  <option>Web Development</option>
                  <option>UI/UX Design</option>
                  <option>Digital Marketing</option>
                  <option>Support</option>
                  <option>Other</option>
                </select>
              </div>
              <div className="col-md-12 mb-6 input-subject">
                <h5>Your Name:</h5>
                <span className="mb-6">
                  Please enter your real name, we like to be formally
                  introduced!:
                </span>
                <input
                  type="name"
                  name="name"
                  className="form-control form-control-lg bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray  font-size-5"
                  placeholder="Your Name*"
                  id="email"
                  required
                />
              </div>
              <div className="col-md-12 mb-6 input-subject">
                <h5>Your Email Address:</h5>
                <span className="mb-6">
                  Make sure it’s correct, or we can’t get back to you!
                </span>
                <input
                  type="email"
                  name="email"
                  className="form-control form-control-lg bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray  font-size-5"
                  placeholder="Email Address*"
                  id="email"
                  required
                />
              </div>
              <div className="col-md-12 mb-6 input-subject">
                <h5>Give us the detail:</h5>
                <span className="mb-6">
                  Provide as much details you can — it helps!
                </span>
                <textarea
                  name="message"
                  className="form-control form-control-lg bg-white rounded-4 text-dark-cloud text-placeholder-bali-gray  font-size-5"
                  placeholder="I have a problem with..."
                  required
                ></textarea>
              </div>
              <div className="mb-3 pt-10 ml-6">
                <button
                  className="btn btn-sky-blue btn-3 rounded-4"
                  type="submit"
                >
                  Submit Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
